import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

TimeAgo.addDefaultLocale(en)

export function timeAgo(date: Date):string {
  const timeAgo = new TimeAgo('en-US')
  return timeAgo.format(date)
}

export function isInThePast(timestamp: string): boolean {
  const date = new Date(timestamp);
  const now = new Date();
  return date < now;
}

export function isInTheFuture(timestamp: string): boolean {
  const date = new Date(timestamp);
  const now = new Date();
  return date > now;
}

// Courtesy of ChatGPT!
export function lastSeenAtDescription(lastSeenAtTimestamp: string): string | null {
  if (!lastSeenAtTimestamp) {
    return null;
  }

  const now = new Date();
  const lastSeen = new Date(lastSeenAtTimestamp);

  const elapsedMilliseconds = now.getTime() - lastSeen.getTime();
  const elapsedSeconds = elapsedMilliseconds / 1000;
  const elapsedMinutes = elapsedSeconds / 60;
  const elapsedHours = elapsedMinutes / 60;
  const elapsedDays = elapsedHours / 24;
  const elapsedWeeks = elapsedDays / 7;
  const elapsedMonths = elapsedDays / 30;

  if (elapsedMinutes < 5) {
    return 'Active now';
  } else if (elapsedMinutes < 60) {
    const increments = Math.floor(elapsedMinutes / 5);
    return `Last active ${increments * 5} minutes ago`;
  } else if (elapsedHours < 12) {
    if (Math.floor(elapsedHours) === 1) {
      return 'Last active an hour ago';
    } else {
      return `Last active ${Math.floor(elapsedHours)} hours ago`;
    }
  } else if (elapsedDays < 2) {
    return 'Last active a day ago';
  } else if (elapsedDays < 7) {
    return `Last active ${Math.floor(elapsedDays)} days ago`;
  } else if (elapsedWeeks < 10) {
    if (Math.floor(elapsedWeeks) === 1) {
      return 'Last active a week ago';
    } else {
      return `Last active ${Math.floor(elapsedWeeks)} weeks ago`;
    }
  } else {
    return `Last active ${Math.floor(elapsedMonths)} months ago`;
  }
}
